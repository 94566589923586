<template>
  <!-- BEGIN: Content-->
  <div class="content-wrapper">
    <div class="content-header row"></div>
    <div class="content-body">
      <div class="auth-wrapper auth-cover">
        <div class="auth-inner row m-0">
          <!-- Brand logo-->
          <a class="brand-logo" href="index.html">
            <img
              style="width: 150px"
              class="img-fluid"
              src="/app-assets/images/vakilgiri-logo.png"
              alt="Login V2"
            />
          </a>
          <!-- /Brand logo-->
          <!-- Left Text-->
          <div class="d-none d-lg-flex auth-bg-2 col-lg-8 align-items-center p-1">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <!-- <img
                  class="img-fluid"
                  src="/app-assets/images/Team-pana.png"
                  alt="Login V2"
                /> -->

              <div
                id="carouselExampleControls"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      style="height: 400px"
                      src="/app-assets/images/Team-pana.png"
                      class="d-block w-100"
                      alt="image1"
                    />
                    <div class="d-block text-center pt-5">
                      <h4>Lorem, ipsum dolor.</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita,
                        voluptate odit! Sint suscipit enim iste facere vero debitis
                        provident ratione temporibus nihil natus, at quos ducimus tempora
                        aliquam aliquid deserunt.
                      </p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <img
                      style="height: 400px"
                      src="/app-assets/images/slider-1.png"
                      class="d-block w-100"
                      alt="image2"
                    />
                    <div class="d-block text-center pt-5">
                      <h4>Lorem, ipsum dolor.</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita,
                        voluptate odit! Sint suscipit enim iste facere vero debitis
                        provident ratione temporibus nihil natus, at quos ducimus tempora
                        aliquam aliquid deserunt.
                      </p>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <img
                      style="height: 400px"
                      src="/app-assets/images/slider-4.png"
                      class="d-block w-100"
                      alt="image3"
                    />
                    <div class="d-block text-center pt-5">
                      <h4>Lorem, ipsum dolor.</h4>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita,
                        voluptate odit! Sint suscipit enim iste facere vero debitis
                        provident ratione temporibus nihil natus, at quos ducimus tempora
                        aliquam aliquid deserunt.
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
          <!-- /Left Text-->
          <!-- Login-->

          <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
              <h2 class="card-title fw-bold mb-1 text-white">Welcome to Vakilgiri 👋</h2>
              <p class="card-text mb-2">
                Please sign-in to your account and start the adventure
              </p>
              <form @submit.prevent="login">
                <div class="form-group" style="padding: 5px 5px 5px 5px">
                  <label class="form-control-label">username</label>
                  <input
                    type="email"
                    v-model="userEmail"
                    autocomplete="off"
                    required
                    placeholder="example@gmail.com"
                    class="form-control"
                  />
                </div>
                <div class="form-group" style="padding: 5px 5px 5px 5px">
                  <label class="form-control-label">password</label>

                  <div class="input-group input-group-merge form-password-toggle">
                    <input
                      v-model="password"
                      :type="showPassword == true ? 'text' : 'password'"
                      required
                      placeholder="Password"
                      class="form-control"
                      i
                    />
                    <span @click="toggleShow" class="input-group-text cursor-pointer">
                      <img
                        v-if="showPassword"
                        style="width: 20px"
                        src="/assets/image/view.png"
                        alt="image2"
                      />
                      <img
                        v-else
                        style="width: 20px"
                        src="/assets/image/hide.png"
                        alt="image2"
                      />
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12">
                    <button
                      type="submit"
                      style="background-color: #f21300; box-shadow: 1px 1px 3px #f21000"
                      class="btn btn-sm text-white mt-1"
                    >
                      Sign in
                    </button>
                  </div>
                  <div class="col-xl-6 col-lg-6 col-md-6 col-12 text-end">
                    <router-link to="/professional/forgotpassword"
                      ><small class="mt-5" style="color: #f21300"
                        >Forgot Password?</small
                      ></router-link
                    >
                  </div>
                </div>
              </form>
              <!-- <p class="text-center mt-2">
                  <span>New on our platform?</span
                  ><a href="auth-register-cover.html"
                    ><span>&nbsp;Create an account</span></a
                  >
                </p> -->
              <!-- <div class="divider my-2">
                  <div class="divider-text">or</div>
                </div> -->
              <!-- <div class="auth-footer-btn d-flex justify-content-center">
                  <a class="btn btn-facebook" href="#"><i data-feather="facebook"></i></a
                  ><a class="btn btn-twitter white" href="#"
                    ><i data-feather="twitter"></i></a
                  ><a class="btn btn-google" href="#"><i data-feather="mail"></i></a
                  ><a class="btn btn-github" href="#"><i data-feather="github"></i></a>
                </div> -->
            </div>
          </div>
          <!-- /Login-->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->

  <div v-if="loading == true">
    <Spinner />
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="panVerifiy"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body text-center">
          <form id="formId" @submit.prevent="otpverifing()">
            <h4>OTP Verification</h4>
            <span>Please Check Your Email </span>
            <div class="mt-2 d-flex gap-2 text-center">
              <!-- <input
                class="form-control intp-otp"
                v-for="index in 6"
                :key="index"
                ref="otpFields"
                v-model="otp[index - 1]"
                @input="handleInput(index)"
                @keypress="allowOnlyNumbers"
                maxlength="1"
                placeholder="-"
              /> -->

              <input
              required
              style="padding:3px 0px 3px 0px"
  class="form-control intp-otp text-center"
  v-for="index in 6"
  :key="index"
  ref="otpFields"
  v-model="otp[index - 1]"
  @input="handleInput(index)"
  @keypress="allowOnlyNumbers"
  maxlength="1"
  placeholder="-"
/>

              <br />
            </div>
            <button
              type="submit"
              class="form-control btn-cus btn btn-sm mt-1 pt-1 pb-1"
              style="background-color: #00364f; color: white"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end of the model -->
</template>

<script>
import jQuery from "jquery";
let $ = jQuery;
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/professional/comman/Spinner.vue";
export default {
  name: "Login",
  components: {
    Spinner,
  },
  data() {
    return {
      otp: ["", "", "", "", "", ""],
      showPassword: false,
      // password: null,
      msgError: "",
      status: "",
      password: "",
      userEmail: "",
      userLoginData: {},
      loading: false,
    };
  },
  created() {
    if (localStorage.getItem("accessToken") == "reset") {
      localStorage.removeItem("accessToken");
    } else {
      if (localStorage.getItem("accessToken") != null) {
        this.$router.push({ name: "Home" });
      }
    }
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    handleInput(index) {
      const enteredValue = this.otp[index - 1];

      // Check if the entered value is a number
      if (/^\d+$/.test(enteredValue) && enteredValue.length === 1 && index < 6) {
        this.$refs.otpFields[index].focus();
      } else if (enteredValue.length === 0 && index > 1) {
        this.$refs.otpFields[index - 2].focus();
      }
    },
    allowOnlyNumbers(event) {
      // Allow only numeric characters
      const keyCode = event.keyCode;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8) {
        event.preventDefault();
      }
    },
    otpverifing() {
      console.log(this.otp);
      var finalOtp = "";
      for (var i = 0; i < this.otp.length; i++) {
        this.otp[i];
        finalOtp = finalOtp + this.otp[i];
      }

      this.loading = true;
      this.$axios
        .post("professional/verify-otp", { otp: finalOtp, email: this.userEmail })
        .then((res) => {
          console.log(res.data);
          $("#panVerifiy").modal("hide");
          console.log(res.data);
          this.userLoginData = res.data.data.user;
          localStorage.setItem("userData", JSON.stringify(this.userLoginData));
          localStorage.setItem("accessToken", res.data.data.token);

          toast.success("Logged In Successfully", {
            autoClose: 1000,
          });

          this.msgError = "";
          this.$router.push("/professional/home");
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    login() {
      this.loading = true;
      this.$axios
        .post("professional/sign-in", { password: this.password, email: this.userEmail })
        .then((res) => {
          localStorage.setItem("winLoad", "true");
          if (res.data.otp == "true") {
            $("#panVerifiy").modal("show");
          } else {
            console.log(res.data);
            this.userLoginData = res.data.data.user;
            localStorage.setItem("userData", JSON.stringify(this.userLoginData));
            localStorage.setItem("accessToken", res.data.data.token);

            toast.success("Logged In Successfully", {
              autoClose: 1000,
            });

            this.msgError = "";
            this.$router.push("/professional/home");
            // this.$router.go({ path: "/professional/home" });
            // this.$router.push({ name: "Home" });
          }
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // error.data.message;
          // this.msgError = error.response.data;
          // console.log(error.response.data);
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    },
  },
};
</script>
<style>
.auth-bg {
  background: #00364f !important;
  color: white !important;
}
</style>
